import { getCurrentLocation } from '@helpers/geo-location'
import { getLineItems } from '@helpers/checkout/global'
import { setupCheckoutAnalytics } from '@helpers/google-tag-manager'
import { getFromBrowserStorage } from '@helpers/storage'
import { createOrder, updateLineItems } from '../../services/checkout'

export const handleLocalCartOrder = setCart => {
  const localCart = getFromBrowserStorage('local', 'cart')
  if (localCart) {
    setCart(localCart)
    setupCheckoutAnalytics(localCart)
  }
}

// check if local payment type is valid
const checkPaymentType = (storage, localOrder) => {
  if (localOrder && localOrder.paymentInfo && storage && storage.paymentInfo) {
    return localOrder.paymentInfo.some(payment => payment.paymentType === 'CYBER')
  }
  return false
}

export const handleLocalSessionOrder = async props => {
  // check if session order exists
  const orderStorage = getFromBrowserStorage('session', 'order')
  const hasDeprecatedPaymentType = checkPaymentType(orderStorage, props.order)

  // assess local storage state
  const hasLineItems = orderStorage && orderStorage.lineItems && orderStorage.lineItems.length > 0
  const orderWasUpdated = JSON.stringify(props.order) !== JSON.stringify(orderStorage)

  // pass access to action creators
  const { onSetCheckoutStep, onSetOrder } = props

  // if the local storage order was updated and has a valid payment type, push new order info
  if (hasLineItems && orderWasUpdated && !hasDeprecatedPaymentType) {
    onSetOrder(orderStorage)
  }

  // if the local storage order exists and has an id, push relevant orde info
  if (orderStorage && orderStorage.orderId) {
    const lineItems = getLineItems()
    const orderResp = await updateLineItems({
      orderId: orderStorage.orderId,
      lineItems,
    })
    if (orderResp && orderResp.orderId) {
      onSetOrder(orderResp)
    }
  }

  // if the local storage order/payment type is invalid, manually create a new order
  if (hasDeprecatedPaymentType || !orderStorage || (orderStorage && !orderStorage.orderId)) {
    const location = getCurrentLocation()
    const lineItems = getLineItems()
    const orderResp = await createOrder({
      lineItems,
      region: location.region,
      zone: parseInt(location.price_zone),
      distribution_index: parseInt(location.distribution_index),
    })
    onSetCheckoutStep('shipping')
    if (orderResp && orderResp.orderId) {
      onSetOrder(orderResp)
    }
  }
}

// determine if the checkout step should/has change(ed)
export const handleCheckoutSteps = props => {
  const { checkoutStep, onSetCheckoutStep } = props
  const checkoutStepStorage = getFromBrowserStorage('session', 'checkoutStep')
  if (props.order && checkoutStepStorage && checkoutStep !== checkoutStepStorage) {
    onSetCheckoutStep(checkoutStepStorage)
  }
}

// determine if the checkout process has been completed
export const checkoutFinished = props => {
  const { checkoutStepsCompleted, onSetCheckoutStepsCompleted } = props
  const checkoutStepsCompletedStorage = getFromBrowserStorage('session', 'checkoutStepsCompleted')
  if (props.order && checkoutStepsCompletedStorage && checkoutStepsCompleted !== checkoutStepsCompletedStorage) {
    onSetCheckoutStepsCompleted(checkoutStepsCompletedStorage)
  }
}

export const setCheckoutReviewStep = () => {
  sessionStorage.setItem(
    'checkoutStepsCompleted',
    JSON.stringify({
      shipping: true,
      delivery: true,
      payment: true,
    }),
  )
  sessionStorage.setItem('checkoutStep', 'review')
}

export const setCheckoutDeliveryStep = () => {
  sessionStorage.setItem(
    'checkoutStepsCompleted',
    JSON.stringify({
      shipping: true,
      delivery: false,
      payment: false,
    }),
  )
  sessionStorage.setItem('checkoutStep', 'delivery')
}
